// import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import { Toast } from 'vant'
import router from '../router'
import Vue from 'vue'
//判断域名
// 创建axios实例
const Http = axios.create({
  baseURL: 'https://loccitane-backend.chufandz.com/api', // api的base_url
  timeout: 15000, // 请求超时时间
  withCredentials: true, //跨域
})

// request拦截器
Http.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  if (config.method == "post") {
    config.data = qs.stringify(config.data)
  }
  return config
}, error => {
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
Http.interceptors.response.use(
  response => {
    if(response.data.status){
      if(response.data.status==200){
        return response.data;
      }else{
        Toast(response.data.msg)
      }
    }
    return response.data;
  },
  error => {
    console.log('err' + error)

    return Promise.reject(error)
  }
)

export default Http
