import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Http from './untils/http.js'

import {
  Toast
} from 'vant';
import 'vant/lib/toast/style/index'

Vue.config.productionTip = false

Vue.prototype.$http = Http;
Vue.prototype.$toast = Toast;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
